import React, {PropsWithChildren, useState} from 'react'
import {ConfigProvider, Form as AntdForm, FormProps, Spin} from 'antd'
import AntdDatePicker from './DatePicker'
import dayjs from 'dayjs'
import styled from 'styled-components'

const validateMessages = {
  required: '필수 입력입니다.'
}

function Form({children, onFinish, ...rest}: PropsWithChildren<FormProps>) {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({})

  return (
    <ConfigProvider form={{validateMessages}}>
      <Spin spinning={loading}>
        <AntdForm
          layout="vertical"
          autoComplete="off"
          onValuesChange={(changeValues) =>
            setValues((prevState) => {
              return {...prevState, ...changeValues}
            })
          }
          onFinish={() => {
            if (onFinish) {
              setLoading(true)
              onFinish(values)
              setLoading(false)
            }
          }}
          {...rest}
        >
          {children}
          <AntdForm.Item shouldUpdate style={{minHeight: 0, margin: 0}}>
            {(form) => {
              const createdAt = form.getFieldValue('createdAt')

              if (!createdAt) return null
              return (
                <AntdForm.Item label="생성일시">
                  <DatePicker showTime={{format: 'HH:mm:ss'}} value={dayjs(createdAt)} disabled />
                </AntdForm.Item>
              )
            }}
          </AntdForm.Item>

          <AntdForm.Item shouldUpdate style={{minHeight: 0, margin: 0}}>
            {(form) => {
              const updatedAt = form.getFieldValue('updatedAt')

              if (!updatedAt) return null
              return (
                <AntdForm.Item label="업데이트일시">
                  <DatePicker showTime={{format: 'HH:mm:ss'}} value={dayjs(updatedAt)} disabled />
                </AntdForm.Item>
              )
            }}
          </AntdForm.Item>
        </AntdForm>
      </Spin>
    </ConfigProvider>
  )
}

export default Form

const DatePicker = styled(AntdDatePicker)`
  width: 100%;
  &.ant-picker.ant-picker-disabled {
    background: none;
  }

  .ant-picker-input > input[disabled] {
    color: inherit;
  }
`
