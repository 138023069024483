import React, {useContext} from 'react'
import {Button, Dropdown, Menu} from 'antd'
import {useNavigate} from 'react-router'
import {LeftOutlined, SmileOutlined} from '@ant-design/icons'
import userContext from '../contexts/user/UserContext'

function Header() {
  const {user, onLogout} = useContext(userContext)
  const navigate = useNavigate()

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', height: '100%', alignItems: 'center'}}>
      <Button type="text" htmlType="button" style={{marginLeft: '-30px'}} onClick={() => navigate(-1)}>
        <LeftOutlined />
        뒤로
      </Button>
      <Dropdown
        overlay={<Menu style={{textAlign: 'center'}} items={[{key: 'logout', label: '로그아웃', onClick: onLogout}]} />}
      >
        <Button type="text" htmlType="button" style={{marginRight: '-30px'}}>
          <SmileOutlined />
          {user?.name}
        </Button>
      </Dropdown>
    </div>
  )
}

export default Header
