import React, {useCallback, useState} from 'react'
import {Card, Layout, LayoutProps, Typography} from 'antd'
import UserProvider from '../contexts/user/UserProvider'
import Header from '../components/Header'
import SideMenu from '../components/SideMenu'
import {Link} from 'react-router-dom'
import {PlayCircleFilled} from '@ant-design/icons'

function DashboardLayout({children, ...rest}: LayoutProps) {
  const [collapsed, setCollapsed] = useState(false)

  const handleCollapsed = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed)
  }, [])

  return (
    <UserProvider>
      <Layout {...rest} style={{minHeight: '100vh'}}>
        <Layout.Sider collapsible collapsed={collapsed} onCollapse={handleCollapsed} style={{padding: '40px 0'}}>
          <Link to="/">
            <aside
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {!collapsed && (
                <Typography style={{color: 'white', textAlign: 'center', fontSize: '18px'}}>
                  <PlayCircleFilled />
                  &nbsp; Music Admin
                </Typography>
              )}
            </aside>
          </Link>
          <SideMenu />
        </Layout.Sider>
        <Layout>
          <Layout.Header style={{background: '#fff'}}>
            <Header />
          </Layout.Header>
          <Layout.Content style={{margin: '16px'}}>
            <Card style={{margin: '16px 0'}}>{children}</Card>
          </Layout.Content>
        </Layout>
      </Layout>
    </UserProvider>
  )
}

export default DashboardLayout
