import {getMusic} from './index'
import {message} from 'antd'

async function handleFetchMusic(params: IGetMusicParams) {
  try {
    const {data} = await getMusic(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 노래 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '노래 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchMusic
