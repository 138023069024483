import React, {useCallback} from 'react'
import Form from '../../../components/Form'
import {Input, Form as AntdForm, Button, Checkbox, Row, Col, message} from 'antd'
import {postAuth} from '../../../api/auth'
import {useNavigate} from 'react-router'

const {Item} = AntdForm

function Index() {
  const navigate = useNavigate()

  const handleLogin = useCallback(async (data: IPostData) => {
    try {
      await postAuth(data)
      message.success('로그인 성공')
      navigate('/')
    } catch (e: any) {
      message.error('로그인 실패')
    }
  }, [])

  return (
    <Form onFinish={handleLogin} initialValues={{remember: false}} style={{width: '30vw'}}>
      <Item label="아이디" name="name">
        <Input placeholder="아이디를 입력하세요" autoComplete="username" />
      </Item>
      <Item label="비밀번호" name="password">
        <Input.Password placeholder="비밀번호를 입력해주세요" autoComplete="current-password" />
      </Item>
      <Item name="remember" valuePropName="checked">
        <Row justify="end">
          <Col>
            <Checkbox>로그인 유지</Checkbox>
          </Col>
        </Row>
      </Item>
      <Item>
        <Button htmlType="submit" type="primary" style={{width: '100%'}}>
          로그인
        </Button>
      </Item>
    </Form>
  )
}

export default Index
