import React from 'react'
import {Divider, Layout, LayoutProps} from 'antd'

function AuthLayout({title, children, ...rest}: LayoutProps) {
  return (
    <Layout {...rest} style={{minHeight: '100vh', minWidth: 1280}}>
      <Layout.Header />
      <Layout.Content>
        <Divider orientation="center" style={{padding: 48}}>
          {title}
        </Divider>
        <main style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{children}</main>
      </Layout.Content>
    </Layout>
  )
}

export default AuthLayout
