import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'

function Index() {
  const navigate = useNavigate()

  useEffect(() => navigate('/user'))

  return <div>dashboard</div>
}

export default Index
