import React, {useCallback, useLayoutEffect, useMemo, useState} from 'react'
import {message, Modal, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'

import {postMusic} from '../../api/musics'
import HistoryForm from './HistoryForm'
import dayjs from 'dayjs'

function HistoryRegistrationModal({
  selectedRecord,
  setSelectedRecord,
  ...props
}: {selectedRecord: any; setSelectedRecord: React.Dispatch<any>} & ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [data, setData] = useState<IMusic>({})
  const [loading, setLoading] = useState({update: false, delete: false})

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    form.resetFields()
    setIsModalVisible(false)
    setData({})
    navigate({hash: ''}, {replace: true})
  }, [])

  const handleFinish = useCallback(
    async (values: IPutMusicDataRaw & {confirm?: string}) => {
      try {
        if (!id) return message.error('노래 데이터를 불러올 수 없습니다.')

        setLoading((prev) => ({...prev, update: true}))
        const defaultFields = form.getFieldsValue()
        await postMusic({data: [{...defaultFields, ...values}]})
        setLoading((prev) => ({...prev, update: false}))
        message.success('정상적으로 처리되었습니다.')
        handleClose()
      } catch (e: any) {
        message.error(e.message || '노래 생성 실패')
      }
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      const musicData = {
        title: selectedRecord.songName,
        artist: selectedRecord.artist,
        titleImage: selectedRecord.image,
        point: 1,
        recommended: false,
        registered: selectedRecord.registered,
        playedAt: dayjs(selectedRecord.playedAt).format('YYYY-MM-DD'),
        createdAt: selectedRecord.createdAt,
        playtime: dayjs(selectedRecord.playtime).format('mm:ss'),
        albumName: selectedRecord.albumName,
        platform: selectedRecord.platform,
        userName: selectedRecord.userName
      }

      form.setFieldsValue(musicData)
      setData(musicData)
      setIsModalVisible(true)
    }

    return () => setSelectedRecord({})
  }, [id])

  return (
    <>
      <Modal
        title={`유저 히스토리 ${hash} 노래 등록`}
        okText="노래 추가"
        cancelText="취소"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        confirmLoading={!loading}
        okButtonProps={{disabled: data.registered}}
        {...props}
      >
        <HistoryForm type="create" data={data} form={form} onFinish={handleFinish} />
      </Modal>
    </>
  )
}

export default HistoryRegistrationModal
