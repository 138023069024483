import {Radio, Select, Space} from 'antd'
import React, {useCallback, useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router'
import handleFetchUsers from '../api/users/handleFetchUsers'
import Actions from '../components/Actions'
import Table from '../components/Table'
import UserUpdateModal from '../components/Users/UserUpdateModal'
import PageProvider from '../contexts/page/PageProvider'
import getQuery from '../lib/getQuery'
import queryString from 'query-string'
import styled from 'styled-components'

const columns = [
  {key: 'name', dataIndex: 'name', title: '이름'},
  {key: 'email', dataIndex: 'email', title: '이메일'},
  {key: 'type', dataIndex: 'type', title: '타입'},
  {
    key: 'connectInfo',
    dataIndex: 'connectInfo',
    title: '연결 된 서비스',
    render: (connectInfo: IUserConnectType[]) => !!connectInfo.length && connectInfo[0].name
  },
  {key: 'status', dataIndex: 'status', title: '상태'}
]

function Members() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: any = useMemo(() => getQuery(search), [])

  const handleFilter = useCallback(
    (key: string, value: string) => {
      if (!value) delete query[key]
      else query[key] = value.toString()

      navigate({
        search: queryString.stringify(query)
      })
    },
    [query]
  )

  const action = [
    <ActionWrapper>
      <Space>
        <Radio.Group defaultValue="id" onChange={(e) => handleFilter('sort', e.target.value)}>
          <Radio.Button value="id">ID 정렬</Radio.Button>
          <Radio.Button value="name">이름 정렬</Radio.Button>
          <Radio.Button value="createdAt">생성일시 정렬</Radio.Button>
        </Radio.Group>
        <Radio.Group defaultValue="DESC" onChange={(e) => handleFilter('order', e.target.value)}>
          <Radio.Button value="DESC">내림차순</Radio.Button>
          <Radio.Button value="ASC">오름차순</Radio.Button>
        </Radio.Group>
      </Space>
      <Space>
        <Select allowClear placeholder="타입 필터" onChange={(value) => handleFilter('type', value)}>
          <Select.Option value="email">email</Select.Option>
          <Select.Option value="facebook">facebook</Select.Option>
          <Select.Option value="kakao">kakao</Select.Option>
          <Select.Option value="naver">naver</Select.Option>
          <Select.Option value="google">google</Select.Option>
          <Select.Option value="apple">apple</Select.Option>
        </Select>
        <Select allowClear placeholder="연결 된 서비스 필터" onChange={(value) => handleFilter('connection', value)}>
          <Select.Option value="spotify">spotify</Select.Option>
          <Select.Option value="appleMusic">appleMusic</Select.Option>
          <Select.Option value="youtubeMusic">youtubeMusic</Select.Option>
        </Select>
        <Select allowClear placeholder="상태 필터" onChange={(value) => handleFilter('status', value)}>
          <Select.Option value="using">using</Select.Option>
          <Select.Option value="withdraw">withdraw</Select.Option>
        </Select>
      </Space>
    </ActionWrapper>
  ]

  return (
    <PageProvider onFetch={handleFetchUsers}>
      <Actions action={action} />
      <Table
        columns={columns}
        onRow={(record) => ({
          onClick: () => navigate({hash: `#${record.id}`})
        })}
        columnViews={{id: true, createdAt: true}}
      />
      <UserUpdateModal />
    </PageProvider>
  )
}

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export default Members
