import Dashboard from '../pages/index'
import {
  ApiOutlined,
  BankOutlined,
  CustomerServiceOutlined,
  ProfileOutlined,
  SafetyCertificateOutlined,
  UserOutlined
} from '@ant-design/icons'
import Administrators from '../pages/Admins'
import Members from '../pages/Users'
import Musics from '../pages/Musics'
import Badges from '../pages/Badges'
import Connects from '../pages/Connects'
import Histories from '../pages/Histories'

export default [
  {
    name: 'dashboard',
    path: '',
    element: <Dashboard />
  },
  {
    name: 'users',
    displayName: '사용자 관리',
    path: 'user',
    icon: <UserOutlined />,
    element: <Members />
  },
  {
    name: 'admin',
    displayName: '운영진 관리',
    path: 'admin',
    icon: <BankOutlined />,
    element: <Administrators />
  },
  {
    name: 'musics',
    displayName: '노래 관리',
    path: 'music',
    icon: <CustomerServiceOutlined />,
    element: <Musics />
  },
  {
    name: 'badges',
    displayName: '뱃지 관리',
    path: 'badge',
    icon: <SafetyCertificateOutlined />,
    element: <Badges />
  },
  {
    name: 'connects',
    displayName: '연동 서비스 관리',
    path: 'connect',
    icon: <ApiOutlined />,
    element: <Connects />
  },
  {
    name: 'histories',
    displayName: '유저 히스토리',
    path: 'history',
    icon: <ProfileOutlined />,
    element: <Histories />
  }
]
