import React, {useCallback, useEffect, useReducer, useState} from 'react'
import UserContext from './UserContext'
import {message, Spin} from 'antd'
import {useLocation, useNavigate} from 'react-router'
import {deleteAuth, getAuth} from '../../api/auth'

const defaultValue = {
  loggedIn: false
}

function reducer(state: any, action: Action) {
  switch (action.type) {
    case 'UPDATE_USER':
      return {...state, user: action.payload, loggedIn: true}
    case 'LOG_OUT':
      delete state.user
      return {...state, loggedIn: false}
    default:
      return defaultValue
  }
}

function UserProvider({children}: {children: JSX.Element | JSX.Element[]}) {
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(reducer, defaultValue)

  const handleFetchUser = useCallback(async () => {
    setLoading(true)
    try {
      const res = await getAuth()
      dispatch({type: 'UPDATE_USER', payload: res.data})
    } catch (e: any) {
      message.error(e.response.statusText)
      if (e.response.status === 404) {
        dispatch({type: 'LOG_OUT'})
        navigate('/auth')
      }
    }
    setLoading(false)
  }, [])

  const handleLogout = useCallback(async () => {
    try {
      await deleteAuth()
      dispatch({type: 'LOG_OUT'})
      message.success('로그아웃 성공')
      navigate('/auth')
    } catch (e: any) {
      message.error(e.message || '로그아웃 실패')
    }
  }, [])

  useEffect(() => {
    handleFetchUser()
  }, [location.pathname])

  return (
    <UserContext.Provider value={{...state, onLogout: handleLogout}}>
      <Spin spinning={loading} tip="Loading..." style={{minHeight: '100vh'}}>
        {children}
      </Spin>
    </UserContext.Provider>
  )
}

export default UserProvider
