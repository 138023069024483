import React, {useContext, useMemo} from 'react'
import {Table as AntdTable, TableProps} from 'antd'
import dayjs from 'dayjs'
import {useLocation} from 'react-router-dom'
import getQuery from '../lib/getQuery'
import {useNavigate} from 'react-router'
import queryString from 'query-string'
import PageContext from '../contexts/page/PageContext'

interface Props {
  total?: number
  columnViews?: {
    id?: boolean
    createdAt?: boolean
    updatedAt?: boolean
  }
}

function Table({
  columns,
  columnViews = {id: true, createdAt: true, updatedAt: true},
  ...rest
}: TableProps<any> & Props) {
  const navigate = useNavigate()
  const {search} = useLocation()

  const {data, total} = useContext(PageContext)

  const query = useMemo(() => getQuery(search), [search])

  const customColumns = useMemo(() => {
    if (!columns) return undefined

    if (columnViews.id) columns = [{key: 'id', dataIndex: 'id', title: 'ID'}, ...columns]
    if (columnViews.createdAt)
      columns = [
        ...columns,
        {
          key: 'createdAt',
          dataIndex: 'createdAt',
          title: '생성일시',
          render: (date: string) => dayjs(date).format('YYYY-MM-DD HH:mm:ss')
        }
      ]
    if (columnViews.updatedAt)
      columns = [
        ...columns,
        {
          key: 'updatedAt',
          dataIndex: 'updatedAt',
          title: '업데이트일시',
          render: (date?: string) => (date ? dayjs(date).format('YYYY-MM-DD HH:mm:ss') : '')
        }
      ]

    return columns
  }, [])

  return (
    <AntdTable
      rowKey="id"
      columns={customColumns}
      style={{marginTop: 16}}
      dataSource={rest.dataSource || data}
      pagination={{
        total: rest.total || total,
        showSizeChanger: true,
        onChange: (page, pageSize) => {
          if (page > 1) query.start = pageSize * (page - 1)
          else delete query.start

          if (pageSize !== 10) query.perPage = pageSize
          else delete query.perPage

          navigate({search: queryString.stringify(query)})
        },
        ...rest.pagination
      }}
      {...rest}
    />
  )
}

export default Table
