import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'

import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import {deleteMusic, putMusic} from '../../api/musics'
import handleFetchMusic from '../../api/musics/handleFetchMusic'
import MusicForm from './MusicForm'
import {uploadImage} from '../../lib/uploadImage'

function MusicUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [data, setData] = useState<IMusic>({})
  const [loading, setLoading] = useState({update: false, delete: false})

  const {onDataFetch} = useContext(PageContext)

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    form.resetFields()
    setIsModalVisible(false)
    setData({})
    if (onDataFetch) onDataFetch()
    navigate({hash: ''}, {replace: true})
  }, [])

  const handleDelete = useCallback(async () => {
    if (!id) return message.error('노래 데이터를 불러올 수 없습니다.')

    try {
      setLoading((prev) => ({...prev, delete: true}))
      await deleteMusic({id})
      setLoading((prev) => ({...prev, delete: false}))
      message.success('정상적으로 처리되었습니다.')
      handleClose()
    } catch (e: any) {
      message.error(e.message || '노래 삭제 실패')
    }
  }, [id])

  const handleFinish = useCallback(
    async (values: IPutMusicDataRaw & {confirm?: string}) => {
      try {
        if (!id) return message.error('노래 데이터를 불러올 수 없습니다.')

        setLoading((prev) => ({...prev, update: true}))
        const defaultFields = form.getFieldsValue()

        if (values.confirm) delete values.confirm

        if (values.titleImage) {
          values.titleImage = await uploadImage(values.titleImage)
        }

        await putMusic({id}, {...defaultFields, ...values})
        setLoading((prev) => ({...prev, update: false}))
        message.success('정상적으로 처리되었습니다.')
        if (onDataFetch) await onDataFetch()
        handleClose()
      } catch (e: any) {
        message.error(e.message || '노래 수정 실패')
      }
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      handleFetchMusic({id}).then((data) => {
        setData(data)
        form.setFieldsValue(data)
        setIsModalVisible(true)
      })
    }
  }, [id])

  return (
    <>
      <UpdateModal
        title={`노래 ${hash}`}
        visible={isModalVisible}
        onOk={form.submit}
        onDelete={handleDelete}
        onCancel={handleClose}
        loading={loading}
        {...props}
      >
        <MusicForm type="update" data={data} form={form} onFinish={handleFinish} />
      </UpdateModal>
    </>
  )
}

export default MusicUpdateModal
