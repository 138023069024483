import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import Table from '../components/Table'
import PageProvider from '../contexts/page/PageProvider'
import {Image, Switch} from 'antd'
import handleFetchHistories from '../api/histories/handleFetchHistories'
import HistoryRegistrationModal from '../components/Histories/HistoryRegisterationModal'

const columns = [
  {
    key: 'image',
    dataIndex: 'image',
    title: '앨범자켓',
    render: (image: string) => <Image src={image} width="100px" />
  },
  {key: 'songName', dataIndex: 'songName', title: '제목'},
  {key: 'albumName', dataIndex: 'albumName', title: '앨범명'},
  {key: 'artist', dataIndex: 'artist', title: '가수'},
  {key: 'platform', dataIndex: 'platform', title: '플랫폼'},
  {
    key: 'registered',
    dataIndex: 'registered',
    title: '노래 등록 여부',
    render: (recommended: boolean) => <Switch disabled checked={recommended} />
  }
]

function Histories() {
  const navigate = useNavigate()
  const [selectedRecord, setSelectedRecord] = useState<any>({})

  return (
    <PageProvider onFetch={handleFetchHistories}>
      <Table
        columnViews={{id: true, createdAt: true, updatedAt: false}}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedRecord(record)
              navigate({hash: `#${record.id}`})
            }
          }
        }}
      />
      <HistoryRegistrationModal selectedRecord={selectedRecord} setSelectedRecord={setSelectedRecord} />
    </PageProvider>
  )
}

export default Histories
