import {getAdministrators} from './index'
import {message} from 'antd'

async function handleFetchAdministrators(params: IGetAdministratorsParams) {
  try {
    const {
      data: {data, total}
    } = await getAdministrators(params)

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '운영진 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchAdministrators
