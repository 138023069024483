import {getAdministrator} from './index'
import {message} from 'antd'

async function handleFetchAdministrator(params: IGetAdministratorParams) {
  try {
    const {data} = await getAdministrator(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 운영진 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '운영진 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchAdministrator
