import React, {useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router'
import Actions from '../components/Actions'
import Table from '../components/Table'
import PageProvider from '../contexts/page/PageProvider'
import handleFetchAdministrators from '../api/administrators/handleFetchAdministrators'
import AdministratorCreateModal from '../components/Administrators/AdministratorCreateModal'
import AdministratorUpdateModal from '../components/Administrators/AdministratorUpdateModal'
import {Radio, Space} from 'antd'
import {useCallback} from 'react'
import queryString from 'query-string'
import getQuery from '../lib/getQuery'

const columns = [
  {key: 'nickname', dataIndex: 'nickname', title: '닉네임'},
  {key: 'name', dataIndex: 'name', title: '이름'}
]

function Administrators() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: any = useMemo(() => getQuery(search), [])

  const handleFilter = useCallback(
    (key: string, value: string) => {
      if (!value) delete query[key]
      else query[key] = value.toString()

      navigate({
        search: queryString.stringify(query)
      })
    },
    [query]
  )

  const action = [
    <Space>
      <Radio.Group defaultValue="id" onChange={(e) => handleFilter('sort', e.target.value)}>
        <Radio.Button value="id">ID 정렬</Radio.Button>
        <Radio.Button value="name">이름 정렬</Radio.Button>
        <Radio.Button value="createdAt">생성일시 정렬</Radio.Button>
      </Radio.Group>
      <Radio.Group defaultValue="DESC" onChange={(e) => handleFilter('order', e.target.value)}>
        <Radio.Button value="DESC">내림차순</Radio.Button>
        <Radio.Button value="ASC">오름차순</Radio.Button>
      </Radio.Group>
    </Space>
  ]

  return (
    <PageProvider onFetch={handleFetchAdministrators}>
      <Actions action={action} isSearch={false}>
        <AdministratorCreateModal />
      </Actions>
      <Table
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => navigate({hash: `#${record.id}`})
          }
        }}
      />

      <AdministratorUpdateModal />
    </PageProvider>
  )
}

export default Administrators
