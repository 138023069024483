import React from 'react'
import {Button, Modal, ModalProps, Popconfirm, Skeleton} from 'antd'

function UpdateModal({
  children,
  onDelete,
  loading,
  ...rest
}: ModalProps & {onDelete?: () => any; loading?: {edit?: boolean; delete?: boolean}}) {
  return (
    <>
      <Modal
        forceRender
        footer={[
          onDelete ? (
            <Popconfirm title="정말로 삭제하시겠습니까?" onConfirm={onDelete} okText="삭제" cancelText="취소">
              <Button htmlType="button" key="delete" loading={loading?.delete} danger>
                삭제
              </Button>
            </Popconfirm>
          ) : null,
          <Button htmlType="reset" key="cancel" onClick={rest.onCancel}>
            취소
          </Button>,
          rest.onOk && (
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              onClick={(e) => {
                if (rest.onOk) {
                  rest.onOk(e)
                }
              }}
              loading={loading?.edit}
            >
              수정
            </Button>
          )
        ]}
        {...rest}
      >
        {!!children ? children : <Skeleton />}
      </Modal>
    </>
  )
}

export default UpdateModal
