import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'

import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import UserForm from './UserForm'
import handleFetchUser from '../../api/users/handleFetchUser'

function UserUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)

  const {onDataFetch} = useContext(PageContext)

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: ''}, {replace: true})
  }, [])

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      handleFetchUser({id}).then((data) => form.setFieldsValue(data))
      return () => {
        form.resetFields()
        if (onDataFetch) onDataFetch()
      }
    }
  }, [id])

  return (
    <>
      <UpdateModal title={`사용자 ${hash}`} visible={isModalVisible} onCancel={handleClose} {...props}>
        <UserForm type="update" form={form} />
      </UpdateModal>
    </>
  )
}

export default UserUpdateModal
