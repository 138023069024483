import request from '../../lib/request'

const PATH = '/users'

function getUsers(params: IGetUsersParams) {
  return request({url: PATH, method: 'GET', params})
}

function getUser({id}: IGetUserParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

export {getUsers, getUser}
