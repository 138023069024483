import axios from 'axios'
import {getFiles} from '../api/files'

export const uploadImage = async (
  file: File[] | string,
  kind?: string,
  onProgress?: (event: {percent: number}) => void
) => {
  if (typeof file === 'string') return file

  const {data} = await getFiles({type: 'image', mimeType: file[0].type})

  const splitedUrl = data.url.split('/')

  if (splitedUrl.length >= 5) {
    data.path = [splitedUrl[3], splitedUrl[4].split('?')[0]].join('/')
  }

  console.log(data.path)

  await axios.put(data.url, file[0], {
    headers: {'Content-Type': file[0].type},
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(progressEvent.loaded * 100)
      if (onProgress) onProgress({percent: percentCompleted})
    }
  })

  return data.path
}
