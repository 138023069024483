import {getConnects} from './index'
import {message} from 'antd'

async function handleFetchConnects(params: IGetConnectsParams) {
  try {
    const {
      data: {data, total}
    } = await getConnects(params)

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '연동 서비스를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchConnects
