import React from 'react'
import {Descriptions, Divider, Form as AntdForm, FormInstance, FormProps, Input, InputNumber, Spin, Switch} from 'antd'
import Form from '../Form'
import Upload from '../Upload'
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'

const {Item} = AntdForm

interface IMusicFormProps extends FormProps {
  form: FormInstance
  type: 'create' | 'update'
  data?: IMusic
}

function MusicForm({type, form, data, ...rest}: IMusicFormProps) {
  return !data || Object.keys(data).length ? (
    <>
      <Descriptions title="곡 정보" bordered layout="vertical">
        <Descriptions.Item label="유저명">{data?.userName}</Descriptions.Item>
        <Descriptions.Item label="연동 서비스">{data?.platform}</Descriptions.Item>
        <Descriptions.Item label="앨범명">{data?.albumName}</Descriptions.Item>
        <Descriptions.Item label="곡명">{data?.title}</Descriptions.Item>
        <Descriptions.Item label="가수명">{data?.artist}</Descriptions.Item>
        <Descriptions.Item label="노래 등록 여부">
          {data?.registered ? <CheckOutlined /> : <CloseOutlined />}
        </Descriptions.Item>
        <Descriptions.Item label="청취 날짜">{data?.playedAt}</Descriptions.Item>
        <Descriptions.Item label="플레이 타임">{data?.playtime}</Descriptions.Item>
      </Descriptions>
      <Divider />
      <Form form={form} {...rest}>
        <Item name="title" label="제목" rules={[{type: 'string', required: type === 'create'}]}>
          <Input placeholder="제목을 입력해주세요." autoComplete="off" />
        </Item>

        <Item name="artist" label="가수" rules={[{type: 'string', required: type === 'create'}]}>
          <Input placeholder="가수를 입력해주세요." autoComplete="off" />
        </Item>

        <Item name="titleImage" label="앨범 자켓" rules={[{required: type === 'create'}]}>
          <Upload title="titleImage" max={1} values={data?.titleImage} />
        </Item>

        <Item name="point" label="포인트" rules={[{type: 'number', required: type === 'create'}]}>
          <InputNumber />
        </Item>

        <Item name="recommended" label="추천 여부">
          <Switch defaultChecked={data?.recommended} />
        </Item>
      </Form>
    </>
  ) : (
    <>
      <Spin style={{width: '100%', height: '520px'}} />
    </>
  )
}

export default MusicForm
