import React from 'react'
import {Form as AntdForm, FormProps, Input} from 'antd'
import Form from '../Form'

const {Item} = AntdForm

function UserForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return (
    <Form {...rest}>
      <Item name="name" label="이름">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="phone" label="전화번호">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="email" label="이메일">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="type" label="타입">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="status" label="상태">
        <Input autoComplete="off" disabled />
      </Item>

      <Item name="platform" label="플랫폼">
        <Input autoComplete="off" disabled />
      </Item>
    </Form>
  )
}

export default UserForm
