import {PropsWithChildren, useCallback, useLayoutEffect, useReducer} from 'react'
import {useLocation} from 'react-router-dom'
import getQuery from '../../lib/getQuery'
import PageContext from './PageContext'

const defaultValue = {
  data: [],
  total: 0
}

function reducer(state: any, action: {type: string; payload?: any}) {
  switch (action.type) {
    case 'GET_LIST':
      return {...state, ...action.payload}
    default:
      return defaultValue
  }
}

function PageProvider({children, onFetch}: PropsWithChildren<IPageProviderProps>) {
  const {search} = useLocation()
  const [state, dispatch] = useReducer(reducer, defaultValue)

  const handleDataFetch = useCallback(
    async (search: any) => {
      if (!onFetch) return dispatch({type: 'INIT'})

      const query = getQuery(search, {start: 0, perPage: 10})
      const data = await onFetch(query)
      dispatch({type: 'GET_LIST', payload: data})
    },
    [onFetch]
  )

  useLayoutEffect(() => {
    handleDataFetch(search)
  }, [search])

  return <PageContext.Provider value={{...state, onDataFetch: handleDataFetch}}>{children}</PageContext.Provider>
}

export default PageProvider
