import React from 'react'
import {Form as AntdForm, FormProps, Input} from 'antd'
import Form from '../Form'
import Password from '../Password'

const {Item} = AntdForm

function AdministratorForm({type, ...rest}: FormProps & {type: 'create' | 'update'}) {
  return (
    <Form {...rest}>
      <Item
        name="name"
        label="이름"
        rules={[
          {required: type === 'create'},
          {pattern: /^[0-9a-zA-Z!_]{4,20}$/, message: '4 ~ 20자 이내의 영어, 숫자, 일부 특수문자(!, _)로 입력해주세요.'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Item
        name="nickname"
        label="닉네임"
        rules={[
          {required: type === 'create'},
          {type: 'string', min: 4, max: 20, message: '4 ~ 20자 이내로 입력해주세요.'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Password rules={[{required: type === 'create'}]} />

      <Password type="confirm" name="confirm" label="비밀번호 확인" rules={[{required: type === 'create'}]} />

      <Item
        name="phone"
        label="전화번호"
        rules={[
          {required: type === 'create'},
          {
            type: 'string',
            pattern: /^[0-9a-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/,
            message: '전화번호 형식이 올바르지 않습니다.'
          }
        ]}
      >
        <Input placeholder="전화번호를 입력해주세요." autoComplete="off" />
      </Item>

      <Item
        name="email"
        label="이메일"
        rules={[{required: type === 'create'}, {type: 'email', message: '이메일 형식이 올바르지 않습니다.'}]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>
    </Form>
  )
}

export default AdministratorForm
