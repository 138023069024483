import request from '../../lib/request'

const PATH = '/badges'

function postBadge(data: IPostBadgeData) {
  return request({url: PATH, method: 'POST', data})
}

function getBadges(params: IGetBadgesParams) {
  return request({url: PATH, method: 'GET', params})
}

function getBadge({id}: IGetBadgeParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putBadge({id}: IPutBadgeParams, data: IPutBadgeData) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

function deleteBadge({id}: IDeleteBadgeParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postBadge, getBadges, getBadge, putBadge, deleteBadge}
