import {message} from 'antd'
import {getHistories} from '.'

async function handleFetchHistories() {
  try {
    const {
      data: {data, total}
    } = await getHistories()

    return {data, total}
  } catch (e: any) {
    message.error(e.message || '유저 히스토리 목록을 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return {data: [], total: 0}
  }
}

export default handleFetchHistories
