import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'

import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import ConnectForm from './ConnectForm'
import {deleteConnect, putConnect} from '../../api/connects'
import handleFetchConnect from '../../api/connects/handleFetchConnect'
import {uploadImage} from '../../lib/uploadImage'

function ConnectUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [titleImage, setTitleImage] = useState('')
  const [checkedTitleImage, setCheckedTitleImage] = useState('')
  const [loading, setLoading] = useState({
    edit: false,
    delete: false
  })

  const {onDataFetch} = useContext(PageContext)

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: ''}, {replace: true})
  }, [])

  const handleDelete = useCallback(async () => {
    if (!id) return message.error('연동 서비스 데이터를 불러올 수 없습니다.')

    try {
      setLoading((prev) => ({...prev, delete: true}))
      await deleteConnect({id})
      setLoading((prev) => ({...prev, delete: false}))
      message.success('정상적으로 처리되었습니다.')
      handleClose()
    } catch (e: any) {
      message.error(e.message || '연동 서비스 삭제 실패')
    }
  }, [id])

  const handleFinish = useCallback(
    async (values: IPutConnectData & {confirm?: string}) => {
      setLoading((prev) => ({...prev, edit: true}))

      if (!id) return message.error('연동 서비스 데이터를 불러올 수 없습니다.')
      const defaultFields = form.getFieldsValue()
      if (values.confirm) delete values.confirm
      if (values.titleImage?.length) {
        values.titleImage = await uploadImage(values.titleImage)
      } else {
        values.titleImage = ''
      }

      if (values.checkedTitleImage?.length) {
        values.checkedTitleImage = await uploadImage(values.checkedTitleImage)
      } else {
        values.checkedTitleImage = ''
      }

      try {
        await putConnect({id}, {...defaultFields, ...values})
        message.success('정상적으로 처리되었습니다.')
        handleClose()
      } catch (e: any) {
        message.error(e.message || '연동 서비스 수정 실패')
      }
      setLoading((prev) => ({...prev, edit: false}))
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      handleFetchConnect({id}).then((data) => {
        setTitleImage(data.titleImage)
        setCheckedTitleImage(data.checkedTitleImage)
        form.setFieldsValue(data)
      })
    }
    return () => {
      form.resetFields()
      setCheckedTitleImage('')
      setTitleImage('')
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <>
      <UpdateModal
        title={`연동 서비스 ${hash}`}
        visible={isModalVisible}
        onOk={form.submit}
        onDelete={handleDelete}
        onCancel={handleClose}
        loading={loading}
        {...props}
      >
        <ConnectForm
          type="update"
          form={form}
          onFinish={handleFinish}
          titleImage={titleImage}
          checkedTitleImage={checkedTitleImage}
        />
      </UpdateModal>
    </>
  )
}

export default ConnectUpdateModal
