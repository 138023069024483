import React, {useCallback, useMemo} from 'react'
import {Menu} from 'antd'
import {useLocation} from 'react-router-dom'
import routes from '../routes/routes'
import {useNavigate} from 'react-router'

function SideMenu() {
  const navigate = useNavigate()
  const {pathname} = useLocation()

  const handleClickMenu = useCallback((event: any) => {
    const href = `/${event.keyPath.reverse().join('/')}`
    navigate(href)
  }, [])

  const items = useMemo(() => {
    const result: any[] = []

    routes.forEach((route) => {
      route.displayName &&
        result.push({
          key: route.path,
          icon: route.icon,
          label: route.displayName
        })
    })

    return result
  }, [])

  return (
    <Menu
      mode="inline"
      theme="dark"
      defaultOpenKeys={[pathname.split('/')[1]]}
      selectedKeys={pathname.replace('/', '').split('/')}
      style={{marginTop: 20}}
      items={items}
      onClick={handleClickMenu}
    />
  )
}

export default SideMenu
