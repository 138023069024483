import React, {useCallback, useContext, useLayoutEffect, useMemo, useState} from 'react'
import {message, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'
import {useNavigate} from 'react-router'
import {useLocation} from 'react-router-dom'

import AdministratorForm from './AdministratorForm'
import {deleteAdministrator, putAdministrator} from '../../api/administrators'
import UpdateModal from '../Modal/UpdateModal'
import PageContext from '../../contexts/page/PageContext'
import handleFetchAdministrator from '../../api/administrators/handleFetchAdministrator'

function AdministratorUpdateModal(props: ModalProps) {
  const navigate = useNavigate()
  const {hash} = useLocation()
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState({update: false, delete: false})

  const {onDataFetch} = useContext(PageContext)

  const id: number | undefined = useMemo(() => {
    return hash ? parseInt(hash.replace('#', '')) : undefined
  }, [hash])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
    navigate({hash: ''}, {replace: true})
  }, [])

  const handleDelete = useCallback(async () => {
    if (!id) return message.error('운영진 데이터를 불러올 수 없습니다.')
    setLoading((prev) => ({...prev, delete: true}))
    try {
      await deleteAdministrator({id})
      message.success('정상적으로 처리되었습니다.')
      handleClose()
    } catch (e: any) {
      message.error(e.message || '운영진 삭제 실패')
    }
    setLoading((prev) => ({...prev, delete: false}))
  }, [id])

  const handleFinish = useCallback(
    async (values: IPutAdministratorData & {confirm?: string}) => {
      if (!id) return message.error('운영진 데이터를 불러올 수 없습니다.')

      if (values.confirm) delete values.confirm

      const defaultFields = form.getFieldsValue()

      try {
        setLoading((prev) => ({...prev, update: true}))
        await putAdministrator({id}, {...defaultFields, ...values})
        setLoading((prev) => ({...prev, update: false}))
        message.success('정상적으로 처리되었습니다.')
        handleClose()
      } catch (e: any) {
        message.error(e.message || '운영진 수정 실패')
      }
    },
    [id]
  )

  useLayoutEffect(() => {
    if (id) {
      setIsModalVisible(true)
      handleFetchAdministrator({id}).then((data) => {
        delete data.password
        form.setFieldsValue(data)
      })
    }
    return () => {
      form.resetFields()
      if (onDataFetch) onDataFetch()
    }
  }, [id])

  return (
    <>
      <UpdateModal
        title={`운영진 ${hash}`}
        visible={isModalVisible}
        onOk={form.submit}
        onDelete={handleDelete}
        onCancel={handleClose}
        loading={loading}
        {...props}
      >
        <AdministratorForm type="update" form={form} onFinish={handleFinish} />
      </UpdateModal>
    </>
  )
}

export default AdministratorUpdateModal
