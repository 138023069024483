import React from 'react'
import {Form as AntdForm, FormInstance, FormProps, Input, InputNumber, Spin, Switch} from 'antd'
import Form from '../Form'
import Upload from '../Upload'

const {Item} = AntdForm

interface IMusicFormProps extends FormProps {
  form: FormInstance
  type: 'create' | 'update'
  data?: IMusic
}

function MusicForm({type, form, data, ...rest}: IMusicFormProps) {
  return !data || Object.keys(data).length ? (
    <Form form={form} {...rest}>
      <Item name="title" label="제목" rules={[{type: 'string', required: type === 'create'}]}>
        <Input placeholder="제목을 입력해주세요." autoComplete="off" />
      </Item>
      <Item name="artist" label="가수" rules={[{type: 'string', required: type === 'create'}]}>
        <Input placeholder="가수를 입력해주세요." autoComplete="off" />
      </Item>

      <Item name="titleImage" label="앨범 자켓">
        <Upload title="titleImage" max={1} values={data?.titleImage} />
      </Item>

      <Item name="point" label="포인트" rules={[{type: 'number', required: type === 'create'}]}>
        <InputNumber />
      </Item>

      <Item name="recommended" label="추천 여부">
        <Switch defaultChecked={data?.recommended} />
      </Item>
    </Form>
  ) : (
    <>
      <Spin style={{width: '100%', height: '520px'}} />
    </>
  )
}

export default MusicForm
