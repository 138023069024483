import React, {useCallback, useContext, useState} from 'react'
import {Button, message, Modal, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'

import AdministratorForm from './AdministratorForm'
import {postAdministrator} from '../../api/administrators'
import PageContext from '../../contexts/page/PageContext'

function AdministratorCreateModal(props: ModalProps) {
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const {onDataFetch} = useContext(PageContext)

  const handleOpen = useCallback(() => {
    form.resetFields()
    setIsModalVisible(true)
  }, [form])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleFinish = useCallback(async (values: IPostAdministratorData) => {
    setLoading(true)
    try {
      await postAdministrator(values)
      if (onDataFetch) await onDataFetch()
      handleClose()
    } catch (e: any) {
      message.error(e.message || '운영진 생성 실패')
    }
    setLoading(false)
  }, [])

  return (
    <>
      <Button onClick={handleOpen}>운영진 생성</Button>
      <Modal
        title="운영진 생성"
        okText="생성"
        cancelText="취소"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        confirmLoading={loading}
        okButtonProps={{disabled: loading}}
        {...props}
      >
        <AdministratorForm type="create" form={form} onFinish={handleFinish} />
      </Modal>
    </>
  )
}

export default AdministratorCreateModal
