import React from 'react'
import {BrowserRouter, Outlet, Route, Routes} from 'react-router-dom'
import AuthLayout from '../layouts/AuthLayout'
import authRoutes from './authRoutes'
import Error404 from '../pages/errors/Error404'
import Error403 from '../pages/errors/Error403'
import Error500 from '../pages/errors/Error500'
import routes from './routes'
import DashboardLayout from '../layouts/DashboardLayout'

function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/auth"
          element={
            <AuthLayout title="Music Admin">
              <Outlet />
            </AuthLayout>
          }
        >
          {authRoutes.map((route) => (
            <Route key={route.name} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route
          path="/"
          element={
            <DashboardLayout>
              <Outlet />
            </DashboardLayout>
          }
        >
          {routes.map((route) => (
            <Route key={route.name} path={route.path} element={route.element}></Route>
          ))}
        </Route>
        <Route path="*" element={<Error404 />}></Route>
        <Route path="/error/403" element={<Error403 />}></Route>
        <Route path="/error/500" element={<Error500 />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Index
