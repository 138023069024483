import React, {useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router'
import Actions from '../components/Actions'
import Table from '../components/Table'
import PageProvider from '../contexts/page/PageProvider'
import {Radio, Space} from 'antd'
import {useCallback} from 'react'
import handleFetchConnects from '../api/connects/handleFetchConnects'
import ConnectCreateModal from '../components/Connects/ConnectCreateModal'
import ConnectUpdateModal from '../components/Connects/ConnectUpdateModal'
import getQuery from '../lib/getQuery'
import queryString from 'query-string'

const columns = [
  {key: 'name', dataIndex: 'name', title: '이름'},
  {key: 'status', dataIndex: 'status', title: '상태'},
  {key: 'cycle', dataIndex: 'cycle', title: '사이클'}
]

function Connects() {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: any = useMemo(() => getQuery(search), [])

  const handleFilter = useCallback(
    (key: string, value: string) => {
      if (!value) delete query[key]
      else query[key] = value.toString()

      navigate({
        search: queryString.stringify(query)
      })
    },
    [query]
  )

  const action = [
    <Space>
      <Radio.Group defaultValue="id" onChange={(e) => handleFilter('sort', e.target.value)}>
        <Radio.Button value="id">ID 정렬</Radio.Button>
        <Radio.Button value="name">이름 정렬</Radio.Button>
        <Radio.Button value="createdAt">생성일시 정렬</Radio.Button>
      </Radio.Group>
      <Radio.Group defaultValue="DESC" onChange={(e) => handleFilter('order', e.target.value)}>
        <Radio.Button value="DESC">내림차순</Radio.Button>
        <Radio.Button value="ASC">오름차순</Radio.Button>
      </Radio.Group>
    </Space>
  ]

  return (
    <PageProvider onFetch={handleFetchConnects}>
      <Actions action={action}>
        <ConnectCreateModal />
      </Actions>
      <Table
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => navigate({hash: `#${record.id}`})
          }
        }}
      />

      <ConnectUpdateModal />
    </PageProvider>
  )
}

export default Connects
