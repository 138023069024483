import request from '../../lib/request'

const PATH = '/musics'

function postMusic(data: IPostMusicData) {
  return request({url: PATH, method: 'POST', data})
}

function getMusics(params: IGetMusicsParams) {
  return request({url: PATH, method: 'GET', params})
}

function getMusic({id}: IGetMusicParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putMusic({id}: IPutMusicParams, data: IPutMusicData) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

function deleteMusic({id}: IDeleteMusicParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postMusic, getMusics, getMusic, putMusic, deleteMusic}
