import React from 'react'
import {Form as AntdForm, FormInstance, FormProps, Input} from 'antd'
import Form from '../Form'
import Upload from '../Upload'

const {Item} = AntdForm

function BadgeForm({
  type,
  form,
  image,
  ...rest
}: FormProps & {form: FormInstance; type: 'create' | 'update'; image?: string}) {
  return (
    <Form form={form} {...rest}>
      <Item name="name" label="이름" rules={[{type: 'string', required: type === 'create'}]}>
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Item name="image" label="이미지" rules={[{required: type === 'create'}]}>
        <Upload title="이미지" max={1} values={image} />
      </Item>

      <Item name="description" label="설명" rules={[{type: 'string', required: type === 'create'}]}>
        <Input placeholder="설명을 입력해주세요" autoComplete="off" />
      </Item>
    </Form>
  )
}

export default BadgeForm
