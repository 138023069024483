import React, {PropsWithChildren} from 'react'
import {Col, Row} from 'antd'
import Search from './Search'
import {SearchProps} from 'antd/lib/input'

function Actions({
  isSearch = true,
  action = [],
  searchProps,
  children
}: PropsWithChildren<{isSearch?: boolean; searchProps?: SearchProps; action?: React.ReactNode[]}>) {
  return (
    <Row justify="space-between" gutter={8}>
      <Col flex="auto">{!!action.length && action}</Col>
      <Col flex="auto">{!!isSearch && <Search {...searchProps} />}</Col>
      <Col>{children}</Col>
    </Row>
  )
}

export default Actions
