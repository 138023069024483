import React from 'react'
import {Form as AntdForm, FormInstance, FormProps, Input} from 'antd'
import Form from '../Form'
import Upload from '../Upload'

const {Item} = AntdForm

function ConnectForm({
  type,
  form,
  titleImage,
  checkedTitleImage,
  ...rest
}: FormProps & {form: FormInstance; type: 'create' | 'update'; titleImage?: string; checkedTitleImage?: string}) {
  return (
    <Form form={form} {...rest}>
      <Item
        name="name"
        label="이름"
        rules={[
          {type: 'string', required: type === 'create'},
          {pattern: /^[0-9a-zA-Z!_]{1,50}$/, message: '숫자, 영문, 일부 특수문자(!, _)만 입력 가능합니다.'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요." autoComplete="off" />
      </Item>

      <Item name="titleImage" label="타이틀 이미지">
        <Upload title="타이틀 이미지" max={1} values={titleImage} />
      </Item>

      <Item name="checkedTitleImage" label="타이틀 이미지(클릭 시)">
        <Upload title="타이틀 이미지(클릭 시)" max={1} values={checkedTitleImage} />
      </Item>

      <Item name="cycle" label="사이클" rules={[{type: 'string', required: type === 'create'}]}>
        <Input placeholder="사이클을 입력해주세요" autoComplete="off" />
      </Item>

      <Item name="memo" label="메모" rules={[{type: 'string'}]}>
        <Input placeholder="메모을 입력해주세요" autoComplete="off" />
      </Item>
    </Form>
  )
}

export default ConnectForm
