import React, {useCallback, useContext, useState} from 'react'
import {Button, message, Modal, ModalProps} from 'antd'
import {useForm} from 'antd/lib/form/Form'

import PageContext from '../../contexts/page/PageContext'
import ConnectForm from './ConnectForm'
import {uploadImage} from '../../lib/uploadImage'
import {postConnect} from '../../api/connects'

function ConnectCreateModal(props: ModalProps) {
  const [form] = useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const {onDataFetch} = useContext(PageContext)

  const handleOpen = useCallback(() => {
    form.resetFields()
    setIsModalVisible(true)
  }, [form])

  const handleClose = useCallback(() => {
    setIsModalVisible(false)
  }, [])

  const handleFinish = useCallback(async (values: IPostConnectData) => {
    setLoading(true)
    try {
      if (values.titleImage) {
        values.titleImage = await uploadImage(values.titleImage)
      }
      if (values.checkedTitleImage) {
        values.checkedTitleImage = await uploadImage(values.checkedTitleImage)
      }
      await postConnect(values)
      if (onDataFetch) await onDataFetch()
      handleClose()
    } catch (e: any) {
      message.error(e.message || '연동 서비스 생성 실패')
    }
    setLoading(false)
  }, [])

  return (
    <>
      <Button onClick={handleOpen}>연동 생성</Button>
      <Modal
        title="연동 서비스 생성"
        okText="생성"
        cancelText="취소"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={handleClose}
        confirmLoading={loading}
        {...props}
      >
        <ConnectForm type="create" form={form} onFinish={handleFinish} />
      </Modal>
    </>
  )
}

export default ConnectCreateModal
