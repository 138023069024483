import request from '../../lib/request'

const PATH = '/administrators'

function postAdministrator(data: IPostAdministratorData) {
  return request({url: PATH, method: 'POST', data})
}

function getAdministrators(params: IGetAdministratorsParams) {
  return request({url: PATH, method: 'GET', params})
}

function getAdministrator({id}: IGetAdministratorParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putAdministrator({id}: IPutAdministratorParams, data: IPutAdministratorData) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

function deleteAdministrator({id}: IDeleteAdministratorParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postAdministrator, getAdministrators, getAdministrator, putAdministrator, deleteAdministrator}
