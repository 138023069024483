import request from '../../lib/request'

const PATH = '/connects'

function postConnect(data: IPostConnectData) {
  return request({url: PATH, method: 'POST', data})
}

function getConnects(params: IGetConnectsParams) {
  return request({url: PATH, method: 'GET', params})
}

function getConnect({id}: IGetConnectParams) {
  return request({url: `${PATH}/${id}`, method: 'GET'})
}

function putConnect({id}: IPutConnectParams, data: IPutConnectData) {
  return request({url: `${PATH}/${id}`, method: 'PUT', data})
}

function deleteConnect({id}: IDeleteConnectParams) {
  return request({url: `${PATH}/${id}`, method: 'DELETE'})
}

export {postConnect, getConnects, getConnect, putConnect, deleteConnect}
