import {message} from 'antd'
import {getConnect} from './index'

async function handleFetchConnect(params: IGetConnectParams) {
  try {
    const {data} = await getConnect(params)

    if (!data.id || data.id !== params.id) throw new Error('해당 연동 서비스 정보가 없습니다.')

    return data
  } catch (e: any) {
    message.error(e.message || '연동 서비스 데이터를 불러올 수 없습니다. 잠시 후 다시 시도해주세요.')

    return null
  }
}

export default handleFetchConnect
