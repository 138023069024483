import React, {useCallback, useMemo} from 'react'
import {SearchProps} from 'antd/lib/input'
import {Input} from 'antd'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import {useNavigate} from 'react-router'
import getQuery from '../lib/getQuery'

function Search(props: SearchProps) {
  const {search} = useLocation()
  const navigate = useNavigate()
  const query: {search?: string} = useMemo(() => getQuery(search), [])

  const handleSubmit = useCallback(
    (value: any) => {
      if (!value) delete query.search
      else query.search = value.toString()

      navigate({
        search: queryString.stringify(query)
      })
    },
    [query]
  )

  return <Input.Search defaultValue={query.search} placeholder="검색" onSearch={handleSubmit} allowClear {...props} />
}

export default Search
