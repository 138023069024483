import request from '../../lib/request'

const PATH = '/auth'

// 로그인
async function postAuth(data: IPostData) {
  try {
    return await request({url: PATH, method: 'POST', data})
  } catch (e: any) {
    switch (e.status) {
      case 404:
        throw new Error('아이디/비밀번호를 확인해주세요')
      default:
        throw e.data
    }
  }
}

// 로그인 세션 확인
function getAuth() {
  return request({url: PATH, method: 'GET'})
}

// 로그아웃
function deleteAuth() {
  return request({url: PATH, method: 'DELETE'})
}

export {postAuth, getAuth, deleteAuth}
